import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  updatetournamentAction,
  getSingleTournamentListAction,
  getDefaultConfigListAction,
} from "../Action/action";
import moment from "moment";

const EditTournament = () => {
  const { id } = useParams();
  const [addtournament, setaddtournament] = useState({});
  const [gettournamentlevellist, settournamentlevelList] = useState([]);

  useEffect(() => {
    getSingletournamentlevel();
    gettournamentlevel();
  }, [id]);

  const gettournamentlevel = async () => {
    let res = await getDefaultConfigListAction({
      skip: 0,
      take: 1000,
    });
    if (res) {
      settournamentlevelList(res);
    }
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setaddtournament((prevTournament) => ({
      ...prevTournament,
      [name]: value,
    }));
  };

  const Form = {
    name: addtournament.name,
    type: addtournament.type,
    registerationFees: 0,
    startTime: moment(addtournament.start_time).toISOString(),
    endTime: moment(addtournament.end_time).toISOString(),
    // lateRegisterationTime: moment(
    //   addtournament.late_registeration_time
    // ).toISOString(),
    startingChips: addtournament.starting_chips,
    // distribution: addtournament.distribution,
    // blinds: addtournament.blinds,
    adminCommission: 0,
    levelIds: addtournament.levels,
    isPrivate: addtournament.is_private === "false" ? false : true,
  };

  const updateTournament = async (e) => {
    e.preventDefault();
    let res = await updatetournamentAction(Form, id);
    if (res === "Tournament Updated Successfully") {
      toast.success(res ?? "Tournament Update");
    } else {
      toast.error(res.response.data[0]);
    }
  };

  const getSingletournamentlevel = async () => {
    let res = await getSingleTournamentListAction(id);
    if (res) {
      setaddtournament({ ...res, levels: res.levels.map((i) => i.id) });
    }
  };
  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-2 pb-2">Edit Tournament</h3>
                </div>

                {/* <div>{mask}</div> */}
              </div>
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            {/*Edit Modal */}
            <div className="">
              <div className="px-4 ">
                <div className="box">
                  <form onSubmit={updateTournament}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter title"
                            onChange={inputHandler1}
                            name="name"
                            value={addtournament.name}
                          />
                        </div>

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select Tournament Type</label>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => inputHandler1(e)}
                              name="type"
                            >
                              <option value="">Select</option>

                              <option
                                selected={addtournament.type === "MTT"}
                                value="MTT"
                              >
                                MTT Tournament{" "}
                              </option>
                              <option
                                selected={addtournament.type === "SNG"}
                                value="SNG"
                              >
                                SNG Tournament{" "}
                              </option>
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select visibility </label>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => inputHandler1(e)}
                              name="is_private"
                            >
                              <option
                                selected={addtournament.is_private === ""}
                                value=""
                              >
                                Select
                              </option>

                              <option
                                selected={addtournament.is_private === false}
                                value={false}
                              >
                                Public{" "}
                              </option>
                              <option
                                selected={addtournament.is_private === true}
                                value={true}
                              >
                                Private{" "}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Starting Chips
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Starting Chips"
                              onChange={inputHandler1}
                              name="starting_chips"
                              value={addtournament.starting_chips}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Start Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Start Time"
                              onChange={inputHandler1}
                              name="start_time"
                              value={moment(addtournament.start_time).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              End Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Start Time"
                              onChange={inputHandler1}
                              name="end_time"
                              value={moment(addtournament.end_time).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            />
                          </div>
                          {/* <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Late Registration Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Late Registration Time"
                              onChange={inputHandler1}
                              name="late_registeration_time"
                              value={moment(
                                addtournament.late_registeration_time
                              ).format("YYYY-MM-DD hh:mm")}
                            />
                          </div> */}
                        </div>
                        {/* <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Distribution
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Distrubation"
                              onChange={inputHandler1}
                              name="distribution"
                              value={addtournament.distribution}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Blinds
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Blinds"
                              onChange={inputHandler1}
                              name="blinds"
                              value={addtournament.blinds}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div> */}

                        <div className="mb-3">
                          <label htmlFor="">Select Tournament Level</label>
                          <div
                            className=" mt-2 bg-white px-3 py-1 newLevel"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              borderRadius: "5px",
                              border: "1px solid #e0e1e2",
                            }}
                          >
                            {/* {addtournament?.levels &&
                              addtournament?.levels?.map((item, index) => {
                                return (
                                  <div key={index} className="my-1">
                                    <input
                                      className=""
                                      style={{ display: "none" }}
                                      type="checkbox"
                                      disabled
                                      checked={true}
                                      // onChange={(e) => {
                                      //   const levels = [...addtournament.levels];

                                      //   if (
                                      //     addtournament?.levels?.indexOf(
                                      //       item.id
                                      //     ) !== -1
                                      //   ) {
                                      //     levels.splice(
                                      //       levels.indexOf(item?.id),
                                      //       1
                                      //     );
                                      //   } else {
                                      //     levels.push(item?.id);
                                      //   }
                                      //   setaddtournament({
                                      //     ...addtournament,
                                      //     levels,
                                      //   });
                                      // }}
                                      name="levelIds"
                                      id={item.id}
                                      value={item.id}
                                    />
                                    <label
                                      htmlFor={item.id}
                                      className="text-black"
                                    >
                                      {" "}
                                      {"Blinds- " +
                                        item.blinds +
                                        " " +
                                        ", " +
                                        "Duration- " +
                                        item.duration +
                                        " " +
                                        ", " +
                                        "Break Duration- " +
                                        item.break_duration +
                                        " "}
                                    </label>
                                  </div>
                                );
                              })} */}

                            {gettournamentlevellist &&
                              gettournamentlevellist?.map((item, index) => {
                                return (
                                  <div key={index} className="my-1">
                                    <input
                                      className=""
                                      style={{ display: "none" }}
                                      type="checkbox"
                                      checked={
                                        addtournament?.levels?.indexOf(
                                          item.id
                                        ) !== -1
                                      }
                                      onChange={(e) => {
                                        const levels = [
                                          ...addtournament.levels,
                                        ];

                                        if (
                                          addtournament?.levels?.indexOf(
                                            item.id
                                          ) !== -1
                                        ) {
                                          levels.splice(
                                            levels.indexOf(item?.id),
                                            1
                                          );
                                        } else {
                                          levels.push(item?.id);
                                        }
                                        setaddtournament({
                                          ...addtournament,
                                          levels,
                                        });
                                      }}
                                      name="levelIds"
                                      id={item.id}
                                      value={item.id}
                                    />
                                    <label
                                      htmlFor={item.id}
                                      className="text-black"
                                    >
                                      {" "}
                                      {/* {item.name} */}
                                      {"Blinds- " +
                                        // "Level Name- " +
                                        // item.name +
                                        // ", " +
                                        item.blinds +
                                        " " +
                                        ", " +
                                        "Duration- " +
                                        item.duration +
                                        " " +
                                        ", " +
                                        "Break Duration- " +
                                        item.break_duration +
                                        " "}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EditTournament;
