import React, { useEffect, useState } from "react";
import { MdAdd, MdDelete, MdRemove } from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {
  getVideosListAction,
  addvideoAction,
  deletevedioAction,
  addQuizAction,
  addRedirectUrlAction,
} from "../Action/action";

const Vedios = () => {
  const [getvideolist, setvideoList] = useState([]);
  const [vedioQuiz, setVedioQuiz] = useState("");
  const [addVideo, setaddVideo] = useState({
    file: "",
    duration: "",
    redirectUrl: "",
  });
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [questions, setQuestions] = useState([
    { text: "", options: [{ text: "", isCorrect: false }] },
  ]);

  useEffect(() => {
    getvedios();
  }, [skip, count]);

  const allQuestion = {
    questions: questions,
  };

  const inputHandler1 = (e) => {
    const { name, files } = e.target;
    const file = files && files.length > 0 ? files[0] : null;
    if (file) {
      setaddVideo((prevTournament) => ({
        ...prevTournament,
        [name]: file,
      }));
    }
    if (file) {
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);
      videoElement.addEventListener("loadedmetadata", () => {
        setaddVideo((prevTournament) => ({
          ...prevTournament,
          duration: Math.floor(videoElement.duration),
        }));
        URL.revokeObjectURL(videoElement.src);
      });
    }
  };

  const handleInputChange = (questionIndex, optionIndex, event) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = {
      ...updatedQuestions[questionIndex].options[optionIndex],
      [name]: value,
    };
    setQuestions(updatedQuestions);
  };

  const handleQuestionInputChange = (questionIndex, event) => {
    const { value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].text = value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      text: "",
      options: [{ text: "", isCorrect: false }],
    };
    const updatedQuestions = [...questions, newQuestion];
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(questionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleCheckboxChange = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options = updatedQuestions[
      questionIndex
    ].options.map((option, idx) => {
      if (idx === optionIndex) {
        return {
          ...option,
          isCorrect: !option.isCorrect,
        };
      }
      return option;
    });
    setQuestions(updatedQuestions);
  };

  const handleRemoveFields = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options = updatedQuestions[
      questionIndex
    ].options.filter((_, idx) => idx !== optionIndex);
    setQuestions(updatedQuestions);
  };

  const handleAddFields = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options = [
      ...updatedQuestions[questionIndex].options,
      { text: "", isCorrect: false },
    ];
    setQuestions(updatedQuestions);
  };

  const getvedios = async () => {
    let res = await getVideosListAction({
      skip: skip,
      take: count,
    });
    setpageCount(Math.ceil(res.pagination.count / count));
    if (res) {
      setpageCount(Math.ceil(res.pagination.count / count));
      setvideoList(res.videos);
    }
  };

  const reverse = getvideolist.map(
    (val, index, array) => array[array.length - 1 - index]
  );

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await getvedios(skip);
    setvideoList(commentsFormServer.videos);
    window.scrollTo(0, 0);
  };

  function editPartner(item) {
    setVedioQuiz(item);
  }

  const columns = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => dataCount * count + index + 1,
    },
    {
      id: "title",
      name: "Name",
      selector: (item) => {
        return `${item.originalName} (${
          item.duration ? item.duration : "36"
        } sec)`;
      },
    },
    {
      id: "title",
      name: "Quizs",
      selector: (item) => {
        return (
          <button
            type="button"
            onClick={() => editPartner(item)}
            className="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            name={item.id}
            value={item.id}
          >
            View
          </button>
        );
      },
    },
    {
      id: "action",
      name: "Action",
      selector: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => vedioDelete(item)}
              className="btn btn-danger mx-1 btn-sm"
            >
              <MdDelete size={15} />
            </button>
          </>
        );
      },
    },
  ];

  function validate() {
    if (addVideo?.file === "") {
      toast.error("Video file is required");
      return false;
    }
    if (addVideo.redirectUrl === "") {
      toast.error("Redirect url is required");
      return false;
    }
    if (!allQuestion.questions || allQuestion.questions.length === 0) {
      toast.error("Question not added at this time");
      return false;
    }
    for (let i = 0; i < allQuestion.questions.length; i++) {
      if (allQuestion.questions.length === 0) {
        toast.error(`Please add at least 1 question`);
        return false;
      }
      const question = allQuestion.questions[i];
      if (question.text === "" || question.text === undefined) {
        toast.error(`Please Enter Question`);
        return false;
      }
      for (let i = 0; i < question.options.length; i++) {
        const options = question.options[i];
        if (options.text === "" || options.text === undefined) {
          toast.error(`Please Enter Options`);
          return false;
        }
      }
    }
    return true;
  }

  const insertVedio = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      setUploadingLoader(true);
      let res = await addvideoAction({
        file: addVideo.file,
        duration: addVideo.duration,
      });
      if (res.videoId) {
        let res1 = await addRedirectUrlAction(
          {
            redirecturl: String(addVideo.redirectUrl),
          },
          res.videoId
        );
        if (res1) {
          setUploadingLoader(false);
          let res2 = await addQuizAction({
            quiz: allQuestion,
            advertisementId: res.videoId,
          });
          if (res2) {
            setUploadingLoader(false);
            toast.success("Video & Quiz Uploaded");
            getvedios();
            document.getElementsByClassName("closeModal1")[0].click();
          } else {
            setUploadingLoader(false);
            toast.error(res.response.data);
          }
        } else {
          setUploadingLoader(false);
          toast.error(res.response.data);
        }
      } else {
        setUploadingLoader(false);
        toast.error(res.response.data);
      }
    }
  };

  const vedioDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Video!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deletevedioAction(e.id);
        if (!res.response) {
          Swal.fire(res, "", "success");
          getvedios();
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  const handleClosed = (e) => {
    e.preventDefault();
    window.location.href = `${config.baseUrl}videos`;
  };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Break Video List</h4>

                      <div className="">
                        <label>Count Per Page : </label>
                        <select
                          onChange={(e) => setCount(e.target.value)}
                          className="px-3 py-1 mx-2 "
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                        <button
                          type="button"
                          // onClick={() => editPartner(item)}
                          className="btn btn-primary "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="box-body">
                      <DataTable
                        columns={columns}
                        striped
                        responsive
                        data={reverse}
                      />
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        All Quizs for this video
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Quizs
                          </label>

                          <div>
                            {vedioQuiz?.quiz &&
                              vedioQuiz?.quiz[0].questions.map(
                                (items, index) => {
                                  return (
                                    <div
                                      className="my-2 p-3"
                                      style={{
                                        border: "1px solid #fff",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <div className="text-white fs-16 ">
                                        {index + 1 + ". " + items?.text}
                                      </div>
                                      <div>
                                        <ul>
                                          {items?.choices &&
                                            items?.choices.map(
                                              (option, index) => {
                                                return (
                                                  <li
                                                    style={{
                                                      color: option.isCorrect
                                                        ? "#2ae711"
                                                        : "",
                                                    }}
                                                    key={index}
                                                  >
                                                    {" "}
                                                    {option?.text}
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>{" "}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>

                        <div className="modal-footer mt-20">
                          <button
                            type="button"
                            className="btn btn-secondary closeModal"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Add Video
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={insertVedio}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Please Select Video
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter title"
                              accept="video/mp4"
                              onChange={inputHandler1}
                              name="file"
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Enter Redirect url
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Redirect Url"
                              onChange={(e) =>
                                setaddVideo((prevTournament) => ({
                                  ...prevTournament,
                                  redirectUrl: e.target.value,
                                }))
                              }
                              name="redirectUrl"
                            />
                          </div>

                          <div className="mt-4">
                            <b className="text-light">Add Quiz</b>
                          </div>
                          <div>
                            {questions &&
                              questions?.map((question, qIndex) => (
                                <div
                                  className="p-3 my-4"
                                  style={{
                                    border: "1px solid #fff",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div key={qIndex}>
                                    <div className="mb-3 mt-1">
                                      <label
                                        htmlFor={`question-${qIndex}`}
                                        className="form-label"
                                      >
                                        Question
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`question-${qIndex}`}
                                        placeholder="Enter title"
                                        onChange={(e) =>
                                          handleQuestionInputChange(qIndex, e)
                                        }
                                        name="text"
                                      />
                                    </div>
                                    {question?.options &&
                                      question?.options.map(
                                        (option, oIndex) => (
                                          <div
                                            className="mb-3 mt-2 d-flex"
                                            style={{ width: "100%" }}
                                            key={oIndex}
                                          >
                                            <div
                                              style={{
                                                width: "85%",
                                                paddingRight: "5px",
                                              }}
                                            >
                                              {oIndex === 0 ? (
                                                <label
                                                  htmlFor={`option-${qIndex}-${oIndex}`}
                                                  className="form-label"
                                                >
                                                  Options (Please select one
                                                  option:
                                                  <span
                                                    style={{ color: "#2ae711" }}
                                                  >
                                                    {" "}
                                                    True
                                                  </span>
                                                  )
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                              <input
                                                type="text"
                                                className="form-control"
                                                id={`option-${qIndex}-${oIndex}`}
                                                placeholder={`Enter Option ${
                                                  oIndex + 1
                                                }`}
                                                value={option.text}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    qIndex,
                                                    oIndex,
                                                    e
                                                  )
                                                }
                                                name="text"
                                              />
                                            </div>

                                            <div
                                              style={{ width: "15%" }}
                                              className=""
                                            >
                                              {oIndex === 0 ? (
                                                <label
                                                  htmlFor={`checkbox-option-${qIndex}-${oIndex}`}
                                                  className="form-label"
                                                >
                                                  Actions
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                              <label
                                                htmlFor={`checkbox-option-${qIndex}-${oIndex}`}
                                                className="p-0 mt-1 text-bold"
                                                style={{
                                                  color: option.isCorrect
                                                    ? "#2ae711"
                                                    : "white",
                                                }}
                                              >
                                                True
                                                <input
                                                  type="checkbox"
                                                  name="isCorrect"
                                                  style={{ display: "none" }}
                                                  id={`checkbox-option-${qIndex}-${oIndex}`}
                                                  checked={option.isCorrect}
                                                  onChange={() =>
                                                    handleCheckboxChange(
                                                      qIndex,
                                                      oIndex
                                                    )
                                                  }
                                                />
                                              </label>
                                              {question?.options?.length >
                                                1 && (
                                                <MdRemove
                                                  size={22}
                                                  className="mx-1"
                                                  color="red"
                                                  onClick={() =>
                                                    handleRemoveFields(
                                                      qIndex,
                                                      oIndex
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        {" "}
                                        <MdAdd
                                          size={22}
                                          className="mx-1"
                                          color="#fff"
                                          onClick={() =>
                                            handleAddFields(qIndex)
                                          }
                                        />{" "}
                                      </div>
                                      <div>
                                        {questions.length > 1 && (
                                          <MdDelete
                                            size={22}
                                            className="mx-1"
                                            color="red"
                                            onClick={() =>
                                              handleRemoveQuestion(qIndex)
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>

                          {uploadingLoader ? (
                            <div className="text-center mt-2">
                              <img
                                src="./images/freebie/new-loading.gif"
                                width={70}
                                height={70}
                                alt="Loading"
                              />
                            </div>
                          ) : (
                            <div className="text-center">
                              {" "}
                              <span
                                className="px-3 py-1"
                                style={{ background: "#fff", color: "#000000" }}
                                onClick={handleAddQuestion}
                              >
                                Add New Question
                              </span>
                            </div>
                          )}

                          <div className="modal-footer mt-20">
                            <button type="submit" class="btn btn-primary">
                              Submit
                            </button>
                            <button
                              className="btn btn-secondary closeModal1"
                              onClick={handleClosed}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Vedios;
