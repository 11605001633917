import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { MdDelete, MdDownload } from "react-icons/md";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { Tooltip } from "react-tooltip";
import {
  getUsersListAction,
  UserBlockAction,
  deleteuserAction,
  getCsvFileUrl,
} from "../Action/action";

const Users = () => {
  const [usersList, setusersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [type, setType] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [csvUrl, setCsvUrl] = useState({});

  useEffect(() => {
    getUsersList();
    getCsvFile();
  }, [skip, count, type]);

  const getCsvFile = async () => {
    try {
      const res = await getCsvFileUrl();
      console.log(res.url, "file url");
      if (res) {
        setCsvUrl(res.url);
      }
    } catch (error) {}
  };
  const getUsersList = async () => {
    try {
      setLoader(true);
      const res = await getUsersListAction({
        type: type,
        skip: skip,
        take: count,
      });

      setusersList(res.allUsers);
      setpageCount(Math.ceil(res.pagination.count / count));
      if (res.allUsers) {
        setpageCount(Math.ceil(res.pagination.count / count));
        setLoader(false);
        setusersList(res.allUsers);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await getUsersList(skip);
    setusersList(commentsFormServer.allUsers);
    window.scrollTo(0, 0);
  };

  const getUsersListReset = async (e) => {
    setType(e);
    try {
      setLoader(true);
      const res = await getUsersListAction({
        type: e,
        skip: skip,
        take: count,
      });
      setusersList(res.allUsers);
      setpageCount(Math.ceil(res.pagination.count / count));
      if (res.allUsers) {
        setpageCount(Math.ceil(res.pagination.count / count));
        setLoader(false);
        setusersList(res.allUsers);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const UserBlock = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: status
        ? "You want to Unblock this User!"
        : "You want to Block this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: status ? "Yes, UnBlock it!" : "Yes, Block it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await UserBlockAction({ id: id });
        if (res) {
          getUsersList();
          Swal.fire(
            res.is_blocked ? "Successfully Blocked!" : "Successfully Unblocked",
            res.msg,
            "success"
          );
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const userDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteuserAction({ userId: e.id });
        if (!res.response) {
          getUsersList();
          Swal.fire(res, "success");
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  const copieBtn = async () => {
    toast.success(`Coppied!!`);
  };

  const columns = [
    {
      id: "Sno.",
      name: "S. No.",
      selector: (row, index) => dataCount * count + index + 1,
    },
    {
      id: "first_name && last_name",
      name: "Name",
      selector: (item) => {
        return `${item.first_name} ${item.last_name}`;
      },
      minWidth: "150px",
    },

    {
      id: "email",
      name: "Email",
      selector: (item) => {
        const emailData =
          item.email === null || item.email === undefined || item.email === ""
            ? item.mobile_no
            : item.email;
        return (
          <>
            {emailData} &nbsp;{" "}
            <CopyToClipboard text={emailData}>
              <sapn
                title="Click to Copy"
                className="mr-copylink"
                id="token-buy-button"
                onClick={copieBtn}
                style={{ cursor: "pointer", color: "rgb(157 81 255)" }}
              >
                <i class="fa fa-copy "></i>
              </sapn>
            </CopyToClipboard>
          </>
        );
      },
      minWidth: "180px",
    },

    {
      id: "mobile_no",
      name: "Mobile No.",
      selector: (item) => {
        return `${item.mobile_no ?? "N/A"}`;
      },
      minWidth: "130px",
    },

    {
      id: "DOB",
      name: "DOB",
      selector: (item) => {
        return `${item.DOB}`;
      },
    },
    {
      id: "gender",
      name: "Gender",
      selector: (item) => {
        return `${item.gender}`;
      },
    },
    {
      id: "winnerlist",
      name: "Payment Details",
      selector: (item) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item.id}`}>
            {" "}
            <button className="btn btn-primary btn-sm">View</button>
          </a>
        );
      },
      minWidth: "130px",
    },

    {
      id: "is_blocked",
      name: "Action",
      selector: (item) => {
        return (
          <div className="">
            <a href={`${config.baseUrl}editUser/${item.id}`}>
              {" "}
              <button className="unblockbutton me-2 p-2">Edit</button>
            </a>{" "}
            <button
              onClick={() => UserBlock(item.id, item.is_blocked)}
              className={
                item.is_blocked == false
                  ? " blockbutton py-2 px-3"
                  : " unblockbutton p-2"
              }
            >
              {item.is_blocked == false ? "Block" : "Unblock"}
            </button>{" "}
            <button
              onClick={() => userDelete(item)}
              className=" blockbutton mx-2 p-2"
            >
              <MdDelete />
            </button>{" "}
          </div>
        );
      },
      minWidth: "200px",
    },
  ];

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-3">Users List</h3>
                </div>
              </div>
              <hr />
            </div>

            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <div>
                        <label>Count Per Page : </label>
                        <select
                          onChange={(e) => setCount(e.target.value)}
                          className="px-3 py-1 mx-2"
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </div>

                      <div className="btnblock">
                        <button
                          className="mainbutton"
                          onClick={() => getUsersListReset(1)}
                          // disabled={type == 1}
                          // style={{
                          //   borderColor: type == 1 ? "black" : "",
                          // }}
                        >
                          Reset
                        </button>
                        <button
                          className="mainbutton"
                          onClick={() => getUsersListReset(2)}
                        >
                          Today
                        </button>
                        <button
                          className="mainbutton"
                          onClick={() => getUsersListReset(3)}
                        >
                          Last Week
                        </button>
                        <button
                          className="mainbutton"
                          onClick={() => getUsersListReset(4)}
                        >
                          {" "}
                          Month
                        </button>
                      </div>
                    </div>

                    <div className="box-body">
                      {!loader ? (
                        <>
                          <div className=" d-flex my-2 justify-content-end">
                            <a
                              className="my-anchor-element btn btn-primary  pull-right"
                              href={csvUrl}
                            >
                              {" "}
                              <MdDownload size={18} /> CSV
                            </a>
                            <Tooltip
                              anchorSelect=".my-anchor-element"
                              place="top"
                            >
                              Download CSV
                            </Tooltip>
                          </div>
                          <DataTable
                            fixedHeader={true}
                            columns={columns}
                            striped
                            responsive
                            data={usersList}
                          />
                        </>
                      ) : (
                        <>
                          <br />
                          <br />
                          <center>
                            <h4>
                              <i className="fa fa-spinner fa-spin"></i> &nbsp;
                              Please wait
                            </h4>
                          </center>
                          <br />
                          <br />
                        </>
                      )}
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Users;
