import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import toast, { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getallticketAction, addticketAnswerAction } from "../Action/action";
import DataTable from "react-data-table-component";

const SupportManagement = () => {
  const [allticketlist, setallticketList] = useState([]);
  const [singleTicket, setsingleTicket] = useState({});
  const [userDetails, setuserDetails] = useState({ answer: "" });
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    getSupportData();
  }, [skip, count]);

  const getSupportData = async () => {
    let res = await getallticketAction({
      skip: skip,
      take: count,
    });
    setpageCount(Math.ceil(res.pagination.count / count));
    if (res) {
      setpageCount(Math.ceil(res.pagination.count / count));
      setallticketList(res.queries);
    }
  };

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await getSupportData(skip);
    setallticketList(commentsFormServer.queries);
    window.scrollTo(0, 0);
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  function validate() {
    if (userDetails.answer === "") {
      toast.error("Answer is required");
      return false;
    }
    return true;
  }

  const insertAnswer = async (e) => {
    e.preventDefault();
    const formData = {
      id: singleTicket.id,
      answer: userDetails.answer,
    };
    const isValid = validate();
    if (isValid) {
      try {
        let res = await addticketAnswerAction(formData);
        if (res) {
          toast.success(res);
          document.getElementsByClassName("closeModal1")[0].click();
          getSupportData();
        } else {
        }
      } catch (error) {}
    }
  };

  function ViewReason(item) {
    setsingleTicket(item);
  }

  const columns = [
    {
      id: "#",
      name: "Sno.",
      selector: (row, index) => dataCount * count + index + 1,
      maxWidth: "10px",
    },
    {
      id: "username",
      name: "Name",
      selector: (item) => {
        return `${item.username}`;
      },
      minWidth: "150px",
    },
    {
      id: "email",
      name: "Email or Mobile",
      selector: (item) => {
        return `${item.mobile == null ? item.email : item.mobile}`;
      },
      minWidth: "150px",
    },
    {
      id: "ticket_number",
      name: "Ticket Number",
      selector: (item) => {
        return `${item.ticket_number}`;
      },
      minWidth: "280px",
    },
    {
      id: "status",
      name: "Status",
      selector: (item) => {
        return (
          <>
            {item.status ? (
              <>
                <div style={{ color: "#008000" }}>
                  <span>Solved</span>
                </div>
              </>
            ) : (
              <>
                <div style={{ color: "red" }}>
                  <span>Pending</span>
                </div>
              </>
            )}
          </>
        );
      },
      maxWidth: "50px",
    },
    {
      id: "datetime",
      name: "Date ",
      selector: (item) => {
        return `${new Date(item.created_at).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        })}`;
      },
      minWidth: "120px",
    },
    {
      id: "reason",
      name: "Reason",
      selector: (item) => {
        return (
          <button
            onClick={() => ViewReason(item)}
            type="button"
            className="btn  btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            View
          </button>
        );
      },
      minWidth: "80px",
    },
    {
      id: "action",
      name: "Action",
      selector: (item) => {
        return (
          <>
            <>
              <button
                disabled={item.status}
                onClick={() => ViewReason(item)}
                type="button"
                className="btn  btn-primary "
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
              >
                Answer
              </button>
              &emsp;
            </>
          </>
        );
      },
      minWidth: "120px",
    },
  ];

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Support Management</h3>
                </div>
              </div>
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <div>
                        <h4 className="box-title">Support Management</h4>
                      </div>
                      <div>
                        <label>Count Per Page : </label>
                        <select
                          onChange={(e) => setCount(e.target.value)}
                          className="px-3 py-1 mx-2 "
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                    </div>
                    <div className="box-body">
                      <DataTable
                        striped
                        responsive
                        columns={columns}
                        data={allticketlist}
                      />
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* "View Reason" */}
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title text-light"
                      id="exampleModalLabel"
                    >
                      {singleTicket.username +
                        "  ( " +
                        (singleTicket.email == null
                          ? singleTicket.mobile
                          : singleTicket.email) +
                        " )"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>

                  <div className="modal-body">
                    <div className="container">
                      <div className="mb-3">
                        <div className="mb-2">
                          <b className="text-light">Title</b>
                        </div>

                        <div>{singleTicket.title}</div>
                      </div>
                      <div className="mb-3">
                        <div className="mb-2">
                          <b className="text-light">Reason</b>
                        </div>

                        <div>{singleTicket.reason}</div>
                      </div>
                      {singleTicket.status ? (
                        <div className="mb-3">
                          <div className="mb-2">
                            <b className="text-light">Answer</b>
                          </div>

                          <div className="mb-1">{singleTicket.answer}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="modal-footer mt-20">
                        <button
                          type="button"
                          className="btn btn-secondary closeModal"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* "Add Answer" */}
            <div
              className="modal fade"
              id="exampleModal1"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title text-light"
                      id="exampleModalLabel"
                    >
                      {singleTicket.title}
                    </h5>
                  </div>
                  <form onSubmit={insertAnswer}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <div className="mb-2">
                            <b className="text-light">Title</b>
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Answer"
                            disabled
                            defaultValue={singleTicket.title}
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            style={{ display: "none" }}
                            value={singleTicket.id}
                            onChange={inputHandler}
                            name="id"
                          />
                          <div className="mb-2 mt-3">
                            <b className="text-light">Answer</b>
                          </div>

                          <textarea
                            rows="5"
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Answer"
                            onChange={inputHandler}
                            name="answer"
                          />
                        </div>

                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default SupportManagement;
