import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { MdRemoveRedEye } from "react-icons/md";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {
  getSingleTournamentListAction,
  getSingleTournamentAnalyticsAction,
} from "../Action/action";

const WinnerList = () => {
  const [getWinnerList, setgetWinnerList] = useState([]);
  const [getAnalyticsData, setgetAnalyticsData] = useState({});
  const [activeKey, setActiveKey] = useState(
    Math.floor(Math.random() * (2 - 1 + 1)) + 1
  );
  const { id } = useParams();

  useEffect(() => {
    getvedios();
    getAnalytics();
  }, [id]);

  const getvedios = async () => {
    let res = await getSingleTournamentListAction(id);
    if (res) {
      setgetWinnerList(res?.joinTournaments);
    }
  };

  const getAnalytics = async () => {
    let res = await getSingleTournamentAnalyticsAction(id);
    if (res) {
      setgetAnalyticsData(res);
    }
  };

  const columnsTotalPaticipants = [
    {
      key: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      key: "title",
      name: "Participants Name",
      selector: (item) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item?.user?.id}`}>
            {item?.user?.first_name + " " + item?.user?.last_name}
          </a>
        );
      },
    },
    {
      key: "view",
      name: "Action",
      selector: (item, index) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item?.user?.id}`}>
            <MdRemoveRedEye size={20} />
          </a>
        );
      },
    },
  ];

  const columnsTotalResponders = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      id: "title",
      name: "Responders",
      selector: (item) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item?.user?.id}`}>
            {item?.user?.first_name + " " + item?.user?.last_name}
          </a>
        );
      },
    },
    {
      id: "view",
      name: "Action",
      selector: (item, index) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item?.user?.id}`}>
            <MdRemoveRedEye size={20} />
          </a>
        );
      },
    },
  ];

  const columnsVideoViewers = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      id: "title",
      name: "Viewers",
      selector: (item) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            href={`${config.baseUrl}userdetails/${item?.viewer?.id}`}
          >
            {item?.viewer?.first_name + " " + item?.viewer?.last_name}
          </a>
        );
      },
    },
    {
      id: "view",
      name: "Action",
      selector: (item, index) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item?.viewer?.id}`}>
            <MdRemoveRedEye size={20} />
          </a>
        );
      },
    },
  ];

  const columnsWinnerList = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      id: "title",
      name: "Winners Name",
      selector: (item) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            href={`${config.baseUrl}userdetails/${item?.user?.id}`}
          >
            {item?.user?.first_name + " " + item?.user?.last_name}
          </a>
        );
      },
    },
    {
      id: "title",
      name: "Rank",
      selector: (item, index) => {
        return index + 1;
      },
    },
    {
      id: "title",
      name: "Amount",
      selector: (item) => {
        return item?.winning_amount;
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: false,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box p-2">
                    <CNav variant="tabs" role="tablist">
                      <CNavItem role="presentation">
                        <CNavLink
                          active={activeKey === 1}
                          component="button"
                          role="tab"
                          style={{ borderRadius: "10px 0px 0px 0px" }}
                          aria-controls="home-tab-pane"
                          aria-selected={activeKey === 1}
                          onClick={() => setActiveKey(1)}
                        >
                          Winner List
                        </CNavLink>
                      </CNavItem>
                      <CNavItem role="presentation">
                        <CNavLink
                          active={activeKey === 2}
                          component="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected={activeKey === 2}
                          onClick={() => setActiveKey(2)}
                        >
                          Analytics
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                    <CTabContent>
                      <CTabPane
                        role="tabpanel"
                        aria-labelledby="home-tab-pane"
                        visible={activeKey === 1}
                      >
                        <div className="box-header with-border">
                          <div>
                            {" "}
                            <h4 className="box-title mb-3">Winner List</h4>
                            <div className=" mt-2">
                              <span className="text-black">
                                {" "}
                                Tournament Id :
                              </span>{" "}
                              <span> {getAnalyticsData.id}</span>
                            </div>
                            <div className=" mt-2">
                              <span className="text-black">
                                {" "}
                                Tournament Name :
                              </span>{" "}
                              <span> {getAnalyticsData.name}</span>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="text-black">
                              Tournament Date :{" "}
                            </span>
                            {new Date(
                              getAnalyticsData.start_time
                            ).toLocaleString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          </div>
                        </div>
                        <div className="box-body">
                          <DataTable
                            columns={columnsWinnerList}
                            pagination
                            striped
                            responsive
                            data={getWinnerList}
                          />
                        </div>
                      </CTabPane>
                      <CTabPane
                        role="tabpanel"
                        aria-labelledby="profile-tab-pane"
                        visible={activeKey === 2}
                      >
                        <div className="box-header with-border">
                          <div>
                            {" "}
                            <h4 className="box-title mb-3">Analytics</h4>
                            <div className=" mt-2">
                              <span className="text-black">
                                {" "}
                                Tournament Id :
                              </span>{" "}
                              <span> {getAnalyticsData.id}</span>
                            </div>
                            <div className=" mt-2">
                              <span className="text-black">
                                {" "}
                                Tournament Name :
                              </span>{" "}
                              <span> {getAnalyticsData.name}</span>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="text-black">
                              Tournament Date :{" "}
                            </span>
                            {new Date(
                              getAnalyticsData.start_time
                            ).toLocaleString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          </div>
                        </div>

                        <div className="box-body">
                          <CAccordion>
                            <CAccordionItem itemKey={1}>
                              <CAccordionHeader>
                                <strong>
                                  Total participants ({" "}
                                  {getAnalyticsData?.joinTournaments?.length ??
                                    0}{" "}
                                  )
                                </strong>
                              </CAccordionHeader>
                              <CAccordionBody>
                                <h6>Total Participant List</h6>
                                <DataTable
                                  columns={columnsTotalPaticipants}
                                  striped
                                  responsive
                                  pagination
                                  data={getAnalyticsData?.joinTournaments}
                                />
                              </CAccordionBody>
                            </CAccordionItem>
                            <CAccordionItem itemKey={2}>
                              <CAccordionHeader>
                                <strong>Commercials</strong>
                              </CAccordionHeader>
                              <CAccordionBody>
                                {" "}
                                {getAnalyticsData &&
                                  getAnalyticsData.tournamentDurationLevels &&
                                  getAnalyticsData.tournamentDurationLevels.map(
                                    (items, index) => {
                                      return (
                                        <CAccordion
                                          className="my-3"
                                          key={index}
                                        >
                                          {items &&
                                            items.advertisements &&
                                            items.advertisements.map(
                                              (subItem, index1) => {
                                                return (
                                                  <>
                                                    <CAccordionItem
                                                      itemKey={subItem.id}
                                                      key={index1}
                                                      style={{
                                                        backgroundColor:
                                                          "#eeeeee",
                                                      }}
                                                    >
                                                      <CAccordionHeader>
                                                        <div className="w-full d-flex justify-content-between">
                                                          <div>
                                                            {
                                                              subItem?.originalName
                                                            }{" "}
                                                          </div>
                                                          <div className="px-3 text-danger">
                                                            {" "}
                                                            ({" "}
                                                            {subItem?.adEngagements &&
                                                              subItem
                                                                ?.adEngagements
                                                                ?.length}{" "}
                                                            Views )
                                                          </div>
                                                        </div>
                                                      </CAccordionHeader>
                                                      <CAccordionBody>
                                                        <CAccordion
                                                          key={index1}
                                                        >
                                                          {subItem?.quiz[0]
                                                            ?.questions &&
                                                            subItem.quiz[0]?.questions.map(
                                                              (
                                                                subItem1,
                                                                index2
                                                              ) => {
                                                                return (
                                                                  <CAccordionItem
                                                                    itemKey={
                                                                      subItem1.id
                                                                    }
                                                                    key={index2}
                                                                  >
                                                                    <CAccordionHeader>
                                                                      <div className="w-full d-flex justify-content-between">
                                                                        <div>
                                                                          {
                                                                            subItem1?.text
                                                                          }{" "}
                                                                        </div>
                                                                        <div className="px-3 text-danger">
                                                                          {" "}
                                                                          ({" "}
                                                                          {subItem1?.responses &&
                                                                            subItem1
                                                                              ?.responses
                                                                              ?.length}{" "}
                                                                          Responses
                                                                          )
                                                                        </div>
                                                                      </div>
                                                                    </CAccordionHeader>
                                                                    <CAccordionBody>
                                                                      <h6 className="box-title mb-3">
                                                                        Responders
                                                                        List
                                                                      </h6>
                                                                      <DataTable
                                                                        columns={
                                                                          columnsTotalResponders
                                                                        }
                                                                        striped
                                                                        responsive
                                                                        pagination
                                                                        data={
                                                                          subItem1?.responses
                                                                        }
                                                                      />
                                                                    </CAccordionBody>
                                                                  </CAccordionItem>
                                                                );
                                                              }
                                                            )}{" "}
                                                        </CAccordion>
                                                        <div className="mt-3 px-1">
                                                          <h6 className="box-title mb-3">
                                                            Video Viewers List
                                                          </h6>
                                                        </div>
                                                        <DataTable
                                                          columns={
                                                            columnsVideoViewers
                                                          }
                                                          striped
                                                          responsive
                                                          pagination
                                                          data={
                                                            subItem?.adEngagements
                                                          }
                                                        />
                                                      </CAccordionBody>
                                                    </CAccordionItem>
                                                  </>
                                                );
                                              }
                                            )}
                                        </CAccordion>
                                      );
                                    }
                                  )}
                              </CAccordionBody>
                            </CAccordionItem>
                          </CAccordion>
                        </div>
                      </CTabPane>
                    </CTabContent>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default WinnerList;
