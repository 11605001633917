import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
// import { getDashboardStatisticsAction } from "../Action/action";

const Dashboard = () => {
  const [statistics, setStatistics] = useState({
    allUsers: 0,
    todayUsers: 0,
    lastWeekUsers: 0,
    lastMonthUsers: 0,
  });
  // s
  // useEffect(() => {
  //   getDashboardStatistics();
  // }, []);

  // const share = () => {
  //   navigator
  //     .share({
  //       title: "POKER",
  //       url: "https://espsofttech.org/forensic/admin/",
  //     })
  //     .then(() => console.log("Successful share"))
  //     .catch((error) => console.log("Error sharing:", error));
  // };

  // const getDashboardStatistics = async () => {
  //   let res = await getDashboardStatisticsAction();
  //   if (res.success) {
  //     let data = res.data;
  //     setStatistics((old) => {
  //       return {
  //         ...old,
  //         allUsers: data.allUsers ?? 0,
  //         todayUsers: data.allUsers ?? 0,
  //         lastWeekUsers: data.allUsers ?? 0,
  //         lastMonthUsers: data.allUsers ?? 0,
  //       };
  //     });
  //   }
  // };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Dashboard</h3>
                </div>
              </div>
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className="content pt-0">
              <div className="row">
                <div className="col-xl-12 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Total Users</h4>
                              </p>
                              <p className="mb-0">
                                <h5>{statistics.allUsers || 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Today Registered</h4>
                              </p>
                              <p className="mb-0">
                                <h5>{statistics.todayUsers || 0}</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Total Chips Sent</h4>
                              </p>
                              <p className="mb-0">
                                <h5>0</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Total Withdrawable Chips</h4>
                              </p>
                              <p className="mb-0">
                                <h5>0</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Total Amount Received</h4>
                              </p>
                              <p className="mb-0">
                                <h5>0</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="box">
                        <div className="box-body">
                          <div className="no-line-chart d-flex align-items-end justify-content-between">
                            <div>
                              <p className="mb-0">
                                <h4>Total Amount Sent</h4>
                              </p>
                              <p className="mb-0">
                                <h5>0</h5>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Dashboard;
