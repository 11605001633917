import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../coreFIles/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  getSingleTournamentListAction,
  grantExtraChipsAction,
  removeUserFromTournamentAction,
  // getDefaultConfigListAction,
} from "../Action/action";
const EditPlayers = () => {
  const { id } = useParams();
  const [tournamentPlayers, settournamentPlayers] = useState([]);
  const [PlayerId, setPlayerId] = useState("");
  const [grantChips, setgrantChips] = useState(0);

  useEffect(() => {
    getSingletournamentlevel();
  }, [id]);

  const getSingletournamentlevel = async () => {
    let res = await getSingleTournamentListAction(id);
    if (res) {
      settournamentPlayers(res?.players);
    }
  };

  const columns = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => index + 1,
    },
    {
      id: "title",
      name: "Name",
      selector: (item) => {
        return (
          <a href={`${config.baseUrl}userdetails/${item.id} `}>
            {item.first_name + " " + item.last_name}
          </a>
        );
      },
    },
    {
      id: "chips",
      name: "Chips",
      selector: (item) => {
        return <span>{item.starting_chips}</span>;
      },
    },

    {
      id: "title",
      name: "Action",
      selector: (item) => {
        return (
          <div className="btn-group">
            <button
              type="button"
              onClick={() => setPlayerId(item.id)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              Update Chips
            </button>
            <button
              onClick={() => registeredUserRemove(item)}
              className="blockbutton"
            >
              Remove
            </button>{" "}
          </div>
        );
      },
    },
  ];

  const registeredUserRemove = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Remove this User From this Tournament!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#dd3333",
      confirmButtonText: "Yes, Remove it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await removeUserFromTournamentAction({
          playerId: e.id,
          tournamentId: id,
        });
        if (!res.response) {
          getSingletournamentlevel();
          Swal.fire("User Remove Successfully !", "", "success");
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  const insertTournament = async (e) => {
    e.preventDefault();
    let res = await grantExtraChipsAction(
      { amount: Number(grantChips), tournamentId: id },
      PlayerId
    );
    if (res) {
      toast.success(res ?? "Extra Chips Added in Player Account");
      getSingletournamentlevel();
      document.getElementsByClassName("closeModal1")[0].click();
    } else {
      toast.error(res);
    }
  };

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-2 pb-2">Edit Players</h3>
                </div>

                {/* <div>{mask}</div> */}
              </div>
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}
            <div
              className="modal fade"
              id="exampleModal1"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title text-light"
                      id="exampleModalLabel1"
                    >
                      Add Chips Amount
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <form onSubmit={insertTournament}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Enter Chips Amount
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Chips Amount"
                            onChange={(e) => setgrantChips(e.target.value)}
                            name="amount"
                            onKeyPress={(event) => {
                              if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/*Edit Modal Ends */}
            {/* /.content */}

            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">
                        Registered Players ({tournamentPlayers.length})
                      </h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                    </div>
                    <div className="box-body">
                      <DataTable
                        columns={columns}
                        striped
                        responsive
                        data={tournamentPlayers}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Add Modal Ends */}
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EditPlayers;
