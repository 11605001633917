import React from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";

const Header = () => {
  const loginData = !Cookies.get("loginSuccessPokerAdmin")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPokerAdmin"));
  if (!loginData || loginData === "") {
    window.location.href = `${config.baseUrl}`;
  }

  const logout = async () => {
    Cookies.remove("loginSuccessPokerAdmin");
    window.location.href = config.baseUrl;
  };

  return (
    <>
      <header className="main-header">
        <div className="d-flex align-items-center logo-box justify-content-start">
          {/* Logo */}
          <span className="logo">
            {/* logo*/}
            <div className="logo-mini w-50">
              <span className="light-logo text-center text-decoration-none">
                <h2>FREEBIE POKER</h2>
              </span>
              <span className="dark-logo text-center text-decoration-none">
                <h2>FREEBIE POKER</h2>
              </span>
            </div>
            <div className="logo-lg">
              <span className="light-logo text-center text-decoration-none">
                <h2>FREEBIE POKER</h2>
              </span>
              <span className="dark-logo text-center text-decoration-none">
                <h2>FREEBIE POKER</h2>
              </span>
            </div>
          </span>
        </div>
        {/* Header Navbar */}
        <nav className="navbar navbar-static-top ">
          {/* Sidebar toggle button*/}
          <div className="app-menu">
            <ul className="header-megamenu nav">
              <li className="btn-group nav-item">
                <a
                  href="#"
                  className="waves-effect waves-light nav-link push-btn btn-primary-light"
                  data-toggle="push-menu"
                  role="button"
                >
                  {/* <i data-feather="align-left" /> */}
                  <i className="fa fa-bars" aria-hidden="true" />
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-custom-menu r-side">
            <ul className="nav navbar-nav">
              <li className="btn-group d-lg-inline-flex d-none">
                {/* <a
                  href="#"
                  data-provide="fullscreen"
                  className="waves-effect waves-light full-screen btn-warning-light"
                  title="Full Screen"
                > */}
                {/* <i data-feather="maximize" /> */}

                <img
                  src="./images/avatar/avatar-1.png"
                  className="avatar rounded-10 bg-primary-light h-40 w-40"
                  alt=""
                  style={{ margin: "25px" }}
                />
                {/* <img
                  src="./images/texas/texas-o-logo.png"
                  className="avatar rounded-10  h-40 w-40"
                  alt=""
                  style={{ margin: "20px" }}
                /> */}

                {/* </a> */}
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
