import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { adduserbyadminAction } from "../Action/action";

const AddUser = () => {
  const [adduser, setadduser] = useState({
    email: "",
    firstname: "",
    lastname: "",
    mobile: "",
    dateOfBirth: "",
    gender: "",
    password: "",
    confirmPassword: "",
    bio: "",
  });

  useEffect(() => {}, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setadduser((old) => {
      return { ...old, [name]: value };
    });
  };

  function validate() {
    if (adduser.email === "") {
      toast.error("Email is required");
      return false;
    }
    if (adduser.firstname === "") {
      toast.error("First Name is required");
      return false;
    }
    if (adduser.lastname === "") {
      toast.error("Last Name is required");
      return false;
    }
    if (adduser.mobile === "") {
      toast.error("Mobile Number is required");
      return false;
    }
    if (adduser.dateOfBirth === "") {
      toast.error("Date Of Birth is required");
      return false;
    }
    if (adduser.gender === "") {
      toast.error("Gender is required");
      return false;
    }
    if (adduser.password === "") {
      toast.error("Password is required");
      return false;
    }
    if (adduser.confirmPassword === "") {
      toast.error("Confirm password is required");
      return false;
    }
    if (adduser.password != adduser.confirmPassword) {
      toast.error("Confirm password dose not match");
      return false;
    }
    return true;
  }

  const registerUser = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        let res = await adduserbyadminAction(adduser);
        if (res) {
          toast.success(res);
          setadduser((old) => {
            return {
              ...old,
              email: "",
              firstname: "",
              lastname: "",
              mobile: "",
              dateOfBirth: "",
              gender: "",
              password: "",
              confirmPassword: "",
              bio: "",
            };
          });
        } else {
        }
      } catch (error) {
        toast.error(
          error?.response?.data == []
            ? error?.response?.data[0]
            : error?.response?.data
        );
      }
    }
  };

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-2 pb-2">Add User</h3>
                </div>

                {/* <div>{mask}</div> */}
              </div>
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            {/*Edit Modal */}
            <div className="">
              <div className="p-4 ">
                <div className="box">
                  <form onSubmit={registerUser}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="w-full d-flex">
                          <div className="w-half pr-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                placeholder="Enter first name"
                                name="firstname"
                                value={adduser.firstname}
                              />
                            </div>
                          </div>
                          <div className="w-half pl-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                placeholder="Enter last name"
                                name="lastname"
                                value={adduser.lastname}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            value={adduser.email}
                            name="email"
                            placeholder="Enter email"
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Mobile Number with country code e.g-(+9188888xxxxx)
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            name="mobile"
                            placeholder="Enter mobile number with country code"
                            value={adduser.mobile}
                          />
                        </div>
                        <div className="w-full d-flex">
                          <div className="w-half pr-2">
                            {" "}
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Date of Birth
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                onChange={inputHandler}
                                name="dateOfBirth"
                                value={adduser.dateOfBirth}
                              />
                            </div>
                          </div>
                          <div className="w-half pl-2">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Gender
                              </label>
                              <select
                                type="name"
                                className="form-control"
                                onChange={inputHandler}
                                name="gender"
                              >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Bio
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            onChange={inputHandler}
                            name="bio"
                            value={adduser.bio}
                            placeholder="Enter bio"
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={inputHandler}
                            name="password"
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={inputHandler}
                            name="confirmPassword"
                          />
                        </div>

                        <div className=" ">
                          <button
                            type="submit"
                            class="btn btn-primary my-20 pull-right"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/*Edit Modal Ends */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default AddUser;
