import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  addtournamentAction,
  getDefaultConfigListAction,
} from "../Action/action";
import moment from "moment";

const AddTournament = () => {
  const [options, setOptions] = useState([{ rank: "", amount: "" }]);
  const [gettournamentlevellist, settournamentlevelList] = useState([]);
  const [addtournament, setaddtournament] = useState({
    levelIds: [],
    name: "",
    type: "",
    registerationFees: null,
    startTime: "",
    endTime: "",
    // lateRegisterationTime: "",
    startingChips: null,
    // distribution: null,
    // blinds: null,
    entryFees: "",
  });


  useEffect(() => {
    gettournamentlevel();
  }, []);

  const inputHandler1 = (e) => {
    const { name, value, checked } = e.target;
    if (name === "levelIds") {
      if (checked) {
        setaddtournament((prevTournament) => ({
          ...prevTournament,
          levelIds: [...prevTournament.levelIds, value],
        }));
      } else {
        setaddtournament((prevTournament) => ({
          ...prevTournament,
          levelIds: prevTournament.levelIds.filter((id) => id !== value),
        }));
      }
    } else {
      setaddtournament((prevTournament) => ({
        ...prevTournament,
        [name]: value,
      }));
    }
  };

  const gettournamentlevel = async () => {
    let res = await getDefaultConfigListAction({
      skip: 0,
      take: 1000,
    });
    if (res) {
      settournamentlevelList(res);
    }
  };

  const Form = {
    levelIds: addtournament.levelIds,
    name: addtournament.name,
    type: addtournament.type,
    registerationFees: 0,
    startTime:  moment(addtournament.startTime).toISOString(),
    endTime: moment(addtournament.endTime).toISOString(),
    // lateRegisterationTime: moment(addtournament.lateRegisterationTime).toISOString(),
    startingChips: addtournament.startingChips,
    // distribution: addtournament.distribution,
    // blinds: addtournament.blinds,
    adminCommission: 0,
    prizePool: options,
    isPrivate: addtournament.isPrivate === "true" ? true : false,
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newQuiz = [...options];
    newQuiz[index][name] = value;
    setOptions(newQuiz);
  };

  const handleAddFields = () => {
    setOptions([...options, { rank: "", amount: "" }]);
  };

  const handleRemoveFields = (index) => {
    const newQuiz = [...options];
    newQuiz.splice(index, 1);
    setOptions(newQuiz);
  };

  function validate() {
    if (addtournament.name === "") {
      toast.error("Tournament title is required");
      return false;
    }
    if (addtournament.type === "") {
      toast.error("Tournament type is required");
      return false;
    }
    if (addtournament.isPrivate === "") {
      toast.error("Visibilty is required");
      return false;
    }
    if (addtournament.startingChips === null) {
      toast.error("Starting Chips is required");
      return false;
    }
    if (addtournament.startTime === "") {
      toast.error("Start Time is required");
      return false;
    }
    if (addtournament.endTime === "") {
      toast.error("End Time is required");
      return false;
    }
    // if (addtournament.lateRegisterationTime === "") {
    //   toast.error("Late Registeration Time is required");
    //   return false;
    // }
    // if (addtournament.distribution === "") {
    //   toast.error("Distribution is required");
    //   return false;
    // }
    // if (addtournament.blinds === "") {
    //   toast.error("Blinds is required");
    //   return false;
    // }
    return true;
  }

  const insertTournament = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      let res = await addtournamentAction(Form);
      if (res === "Tournament Created Successfully") {
        toast.success(res ?? "Tournament Created");
        setOptions([]);
        setaddtournament((old) => {
          return {
            ...old,
            levelIds: [],
            name: "",
            type: "",
            registerationFees: "",
            startTime: "",
            endTime: "",
            // lateRegisterationTime: "",
            startingChips: "",
            // distribution: "",
            // blinds: "",
            entryFees: "",
          };
        });
      } else {
        toast.error(res.response.data[0]);
      }
    }
  };

  return (
    <>
      <div class="wrapper">
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-2 pb-2">Add Tournament</h3>
                </div>

                {/* <div>{mask}</div> */}
              </div>
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}

            <div className="">
              <div className="p-4 ">
                <div className="box">
                  <form onSubmit={insertTournament}>
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter title"
                            onChange={inputHandler1}
                            name="name"
                            value={addtournament.name}
                          />
                        </div>
                        <div className="mb-3"></div>
                        {/* <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select Type</label>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => inputHandler1(e)}
                              name="type"
                            >
                              <option
                                selected={addtournament.type == ""}
                                value=""
                              >
                                Select
                              </option>

                              <option value="MTT">MTT Tournament </option>
                              <option value="SNG">SNG Tournament </option>
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select Tournament Type</label>
                            <br />
                            <label className="switch mt-2">
                              <input type="checkbox" />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div> */}
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select Tournament Type</label>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => inputHandler1(e)}
                              name="type"
                            >
                              <option
                                selected={addtournament.type == ""}
                                value=""
                              >
                                Select
                              </option>

                              <option value="MTT">MTT Tournament </option>
                              {/* <option value="SNG">SNG Tournament </option> */}
                            </select>
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label htmlFor="">Select Visibility </label>
                            <select
                              className="form-control mt-2"
                              onChange={(e) => inputHandler1(e)}
                              name="isPrivate"
                            >
                              <option
                                selected={addtournament.isPrivate == ""}
                                value=""
                              >
                                Select
                              </option>

                              <option value={false}>Public </option>
                              <option value={true}>Private </option>
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Starting Chips
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Starting Chips"
                              onChange={inputHandler1}
                              name="startingChips"
                              value={addtournament.startingChips}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Start Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Late Registration Time"
                              onChange={inputHandler1}
                              name="startTime"
                              value={addtournament.startTime}
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              End Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Start Time"
                              onChange={inputHandler1}
                              name="endTime"
                              value={addtournament.endTime}
                            />
                          </div>
                          {/* <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Late Registration Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Late Registration Time"
                              onChange={inputHandler1}
                              name="lateRegisterationTime"
                              value={addtournament.lateRegisterationTime}
                            />
                          </div> */}
                        </div>
                        {/* <div className="mb-3 d-flex w-full">
                          <div
                            className="w-half"
                            style={{ paddingRight: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Distribution
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Distrubation"
                              onChange={inputHandler1}
                              name="distribution"
                              value={addtournament.distribution}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div
                            className="w-half"
                            style={{ paddingLeft: "10px" }}
                          >
                            {" "}
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Blinds
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Blinds"
                              onChange={inputHandler1}
                              name="blinds"
                              value={addtournament.blinds}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div> */}

                        <div className="mb-3">
                          <label htmlFor="">Select Tournament Level</label>
                          <div
                            className=" mt-2 bg-white px-3 py-1 newLevel"
                            style={{
                              maxHeight: "150px",
                              overflowY: "auto",
                              borderRadius: "5px",
                              border: "1px solid #e0e1e2",
                            }}
                          >
                            {gettournamentlevellist.length == 0 ? (
                              <div className="text-black text-center py-5">
                                No Level Added in configuration
                              </div>
                            ) : (
                              <>
                                {gettournamentlevellist &&
                                  gettournamentlevellist.map((item, index) => {
                                    return (
                                      <div key={index} className="my-1">
                                        <input
                                          className=""
                                          style={{ display: "none" }}
                                          type="checkbox"
                                          onChange={(e) => inputHandler1(e)}
                                          name="levelIds"
                                          id={item.id}
                                          value={item.id}
                                        />
                                        <label
                                          htmlFor={item.id}
                                          className="text-black"
                                        >
                                          {" "}
                                          {/* {item.name} */}
                                          {" " +
                                            // "Level Name- " +
                                            // item.name +
                                            // ", " +
                                            "Blinds- " +
                                            item.blinds +
                                            " " +
                                            ", " +
                                            "Duration- " +
                                            item.duration +
                                            " " +
                                            ", " +
                                            "Break Duration- " +
                                            item.break_duration +
                                            " "}
                                        </label>
                                      </div>
                                    );
                                  })}{" "}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="mb-2 text-black">Prizepool</div>

                        {options &&
                          options.map((prizePool, index) => (
                            <>
                              <div className="mb-3 d-flex w-half">
                                <div
                                  className="w-half"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  {index == 0 ? (
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Rank
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder={`Enter Rank ${index + 1}`}
                                    name="rank"
                                    value={options.text}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                <div
                                  className=""
                                  style={{ paddingLeft: "10px", width: "48%" }}
                                >
                                  {" "}
                                  {index == 0 ? (
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Amount
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Amount"
                                    value={options.amount}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                    name="amount"
                                    onKeyPress={(event) => {
                                      if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                <div style={{ width: "2%" }} className="">
                                  <div className="">
                                    {index == 0 ? (
                                      ""
                                    ) : (
                                      <MdDelete
                                        size={25}
                                        className="mt-1"
                                        color="red"
                                        onClick={() =>
                                          handleRemoveFields(index)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        <div className="">
                          <button
                            type="button"
                            onClick={handleAddFields}
                            style={{ borderRadius: "5px" }}
                            className=" px-2 py-1 fs-12"
                          >
                            <FaPlus />
                          </button>
                        </div>
                        <div className="modal-footer mt-20">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default AddTournament;
