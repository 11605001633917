import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast, Toaster } from "react-hot-toast";
import { MdAdd, MdDelete } from "react-icons/md";
import Select from "react-select";
import SortableList, { SortableItem } from "react-easy-sort";

import Swal from "sweetalert2";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {
  getDefaultConfigListAction,
  addtournamentLevelAction,
  deleteTournamentLevelAction,
  getVideosListAction,
} from "../Action/action";

const TournamentLevel = () => {
  const [gettournamentlevellist, settournamentlevelList] = useState([]);
  const [getvideolist, setvideoList] = useState([]);
  const [levelVideoList, setLevelVideoList] = useState({});
  const [tournamentLevels, setTournamentLevels] = useState([
    {
      name: "",
      duration: "",
      blinds: "",
      break_duration: "",
      advertisementIds: [],
    },
  ]);

  const addInputFields = () => {
    const newTournamentLevels = [
      ...tournamentLevels.map((i) => ({ ...i })),
      {
        name: "",
        duration: "",
        blinds: "",
        break_duration: "",
        advertisementIds: [],
      },
    ];
    setTournamentLevels(newTournamentLevels);
  };

  const removeInputFields = (index) => {
    const newTournamentLevels = tournamentLevels.map((i) => ({ ...i }));
    newTournamentLevels.splice(index, 1);
    setTournamentLevels(newTournamentLevels);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newTournamentLevels = tournamentLevels.map((i) => ({ ...i }));
    newTournamentLevels[index] = {
      ...newTournamentLevels[index],
      [name]: value,
    };
    setTournamentLevels(newTournamentLevels);
  };

  useEffect(() => {
    gettournamentlevel();
    getvedios();
  }, []);

  const getvedios = async () => {
    let res = await getVideosListAction({
      skip: 0,
      take: 1000,
    });
    if (res) {
      setvideoList(res.videos);
    }
  };

  const handleSelectChange = (selectedItems, index) => {
    const updatedLevels = tournamentLevels.map((i) => ({ ...i }));
    updatedLevels[index].advertisementIds = selectedItems;
    setTournamentLevels(updatedLevels);
  };

  const onSortEnd = (index, oldIndex, newIndex) => {
    const newTournamentLevels = tournamentLevels.map((i) => ({ ...i }));
    const sortableItems = newTournamentLevels[index].advertisementIds;
    const movedItem = sortableItems[oldIndex];
    sortableItems.splice(oldIndex, 1);
    sortableItems.splice(newIndex, 0, movedItem);
    setTournamentLevels(newTournamentLevels);
  };

  // -----------------------------------------------Tournament Level List with Pagination & Delete ---------------

  const gettournamentlevel = async () => {
    let res = await getDefaultConfigListAction();
    if (res) {
      settournamentlevelList(res);
    }
  };

  const tournamentlevelDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Level Configuration!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteTournamentLevelAction(e.id);
        if (!res.response) {
          Swal.fire(res, "", "success");
          gettournamentlevel();
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  function editPartner(item) {
    setLevelVideoList(item);
  }

  const columns = [
    {
      name: "Sno.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => {
        return `${row.name}`;
      },
    },

    {
      name: "Duration",
      selector: (row) => {
        return `${row.duration}`;
      },
    },

    {
      name: "Blinds",
      selector: (row) => {
        return `${row?.blinds}`;
      },
    },
    {
      name: "Break Duration",
      selector: (row) => {
        return `${row?.break_duration}`;
      },
    },
    {
      name: "Videos",
      selector: (row) => {
        return (
          <button
            type="button"
            onClick={() => editPartner(row)}
            className="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            name={row.id}
            value={row.id}
          >
            View
          </button>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <>
            <button
              onClick={() => tournamentlevelDelete(row)}
              className="btn btn-danger btn-sm"
              name={row.id}
              value={row.id}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];

  // -----------------------------------------------End Tournament Level List with Pagination & Delete ---------------

  function validate() {
    if (!tournamentLevels || tournamentLevels.length === 0) {
      toast.error("Tournament levels not added at this time");
      return false;
    }
    for (let i = 0; i < tournamentLevels.length; i++) {
      const level = tournamentLevels[i];
      if (level.name === "" || level.name === undefined) {
        toast.error(`Lavel name is required at tournament level ${i + 1}`);
        return false;
      }
      if (level.duration === "" || level.duration === undefined) {
        toast.error(`Duration is required at tournament level ${i + 1}`);
        return false;
      }
      if (level.blinds === "" || level.blinds === undefined) {
        toast.error(`Blinds is required at tournament level ${i + 1}`);
        return false;
      }
      if (level.break_duration === "" || level.break_duration === undefined) {
        toast.error(`Break duration is required at tournament level ${i + 1}`);
        return false;
      }
      if (!level.advertisementIds || level.advertisementIds.length === 0) {
        toast.error(`Video is required at tournament level ${i + 1}`);
        return false;
      }
    }

    return true;
  }

  const levels = {
    levels: tournamentLevels,
  };

  const insertTournament = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        let res = await addtournamentLevelAction(levels);
        if (res) {
          document.getElementsByClassName("closeModal1")[0].click();
          gettournamentlevel();
          toast.success(res);
        } else {
          toast.error(res.msg);
        }
      } catch (error) {}
    }
  };

  const handleClosed = (e) => {
    e.preventDefault();
    window.location.href = `${config.baseUrl}addTournamentLevel`;
  };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Configuration List</h4>
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="box-body mb-3">
                      <DataTable
                        columns={columns}
                        pagination
                        striped
                        responsive
                        data={gettournamentlevellist}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        All Video for this level
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Videos Name
                          </label>

                          <div>
                            <div
                              style={{
                                listStyle: "none",
                                color: "#fff",
                                textTransform: "capitalize",
                              }}
                            >
                              {levelVideoList.advertisements &&
                              levelVideoList.advertisements.length === 0 ? (
                                <div className="text-center py-5">
                                  No video Added
                                </div>
                              ) : (
                                <>
                                  {levelVideoList.advertisements &&
                                    levelVideoList.advertisements.map(
                                      (items, index) => {
                                        return (
                                          <div className="my-1" key={index}>
                                            {index +
                                              1 +
                                              ". " +
                                              items.originalName}
                                          </div>
                                        );
                                      }
                                    )}{" "}
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="modal-footer mt-20">
                          <button
                            type="button"
                            className="btn btn-secondary closeModal"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel"
                      >
                        Add New configuration
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={insertTournament}>
                      <div className="modal-body">
                        <div className="container">
                          {tournamentLevels.map((level, index) => (
                            <>
                              <div className="mt-4">
                                <b className="text-light">
                                  Add Tournament Level {index + 1}
                                </b>
                              </div>
                              <div
                                className="p-3 mt-3 mb-2"
                                style={{
                                  border: "1px solid #fff",
                                  borderRadius: "10px",
                                }}
                              >
                                <div key={index}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`name${index}`}
                                      className="form-label"
                                    >
                                      Level Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`name${index}`}
                                      placeholder="Enter Level Name"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      name="name"
                                      value={level.name}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`duration${index}`}
                                      className="form-label"
                                    >
                                      Level Duration in Seconds
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id={`duration${index}`}
                                      placeholder="Enter Level Duration"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      name="duration"
                                      value={level.duration}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`blinds${index}`}
                                      className="form-label"
                                    >
                                      Level Blinds
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id={`blinds${index}`}
                                      placeholder="Enter Level Blinds"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      name="blinds"
                                      value={level.blinds}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`breakDuration${index}`}
                                      className="form-label"
                                    >
                                      Level Break Duration in Seconds
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id={`breakDuration${index}`}
                                      placeholder="Enter Level Break Duration"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      name="break_duration"
                                      value={level.break_duration}
                                    />
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      Select Break Videos
                                    </label>
                                    <Select
                                      isMulti
                                      value={level.advertisementIds}
                                      options={
                                        getvideolist &&
                                        getvideolist.map((items, idx) => {
                                          return {
                                            value: items.id,
                                            label: items.originalName,
                                          };
                                        })
                                      }
                                      onChange={(selected) =>
                                        handleSelectChange(selected, index)
                                      }
                                    />
                                    <div className="mt-1">
                                      <SortableList
                                        onSortEnd={(oldIndex, newIndex) =>
                                          onSortEnd(index, oldIndex, newIndex)
                                        }
                                        value={level.advertisementIds}
                                        className="list"
                                        draggedItemClassName="dragged"
                                      >
                                        {level.advertisementIds.map(
                                          (item, index) => (
                                            <SortableItem index={index}>
                                              <div className="item">
                                                <div
                                                  className="py-1 px-2 my-1"
                                                  style={{
                                                    background: "#706e6e",
                                                    color: "#fff",
                                                  }}
                                                >
                                                  {item.label}
                                                </div>
                                              </div>
                                            </SortableItem>
                                          )
                                        )}
                                      </SortableList>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    {tournamentLevels.length > 1 && (
                                      <MdDelete
                                        size={22}
                                        className="mx-1"
                                        color="red"
                                        onClick={() => removeInputFields(index)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                          <div style={{ textAlign: "end" }}>
                            {" "}
                            <MdAdd
                              size={22}
                              className="mx-1"
                              style={{ cursor: "pointer" }}
                              color="#fff"
                              onClick={addInputFields}
                            />{" "}
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" class="btn btn-primary">
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal1"
                              data-bs-dismiss="modal"
                              onClick={handleClosed}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default TournamentLevel;
