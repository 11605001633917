import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "../directives/header";
import Sidebar from "../directives/sidebar";
import { getuserDetailsAction, UserBlockAction } from "../Action/action";

const Userdetails = () => {
  const [getuserdetaillist, setusersDetailList] = useState({});

  useEffect(() => {
    getuserDetails();
  }, []);

  const getuserDetails = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getuserDetailsAction({ id: id });
    if (res) {
      setusersDetailList(res);
    }
  };

  const copieBtn = async () => {
    toast.success(`Coppied!!`);
  };

  const UserBlock = async (ids, status) => {
    const id = window.location.href.split("/").pop();
    Swal.fire({
      title: "Are you sure?",
      text: !status
        ? "You want to Block this User!"
        : "You want to Unblock this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: status ? "Yes, UnBlock it!" : "Yes, Block it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await UserBlockAction({ id: id });
        if (res) {
          getuserDetails();
          Swal.fire(
            res.is_blocked ? "Successfully Blocked!" : "Successfully Unblocked",
            res.msg,
            "success"
          );
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Sidebar />
        <Toaster />
        <div className="content-wrapper">
          <div className="container-full">
            <section>
              <div className="container pt-5">
                <div className="row">
                  <div className="col-md-5">
                    <div className="profile_section">
                      <div className="user_profile">
                        <center class="loginLogo">
                          <img
                            src={
                              getuserdetaillist.profile_pic == null
                                ? "./images/freebie/FreebieLogo.png"
                                : getuserdetaillist.profile_pic
                            }
                            alt="No"
                          />
                        </center>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mx-auto">
                        <div className="dummys_btn">
                          {!getuserdetaillist.is_blocked ? (
                            <button
                              type="button"
                              onClick={() => UserBlock(getuserdetaillist.id)}
                              className="btn btn-sm btn-primary"
                            >
                              Block{" "}
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                UserBlock(
                                  getuserdetaillist.id,
                                  getuserdetaillist.is_blocked
                                )
                              }
                              className="btn btn-sm btn-primary"
                            >
                              Unblock{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="user_details">
                      <h3>User Details</h3>
                      <table style={{ width: "100%" }} id="usertableid">
                        <tbody>
                          <tr>
                            <th colspan="2">Full Name:</th>
                            <td colspan="2">
                              {" "}
                              {getuserdetaillist.first_name +
                                " " +
                                getuserdetaillist.last_name}
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2">Mobile:</th>
                            <td colspan="2">
                              {getuserdetaillist.mobile_no}&nbsp;
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2">Email:</th>
                            <td colspan="2">
                              {getuserdetaillist.email}&nbsp;
                              <CopyToClipboard text={getuserdetaillist.email}>
                                <sapn
                                  title="Click to Copy"
                                  className="mr-copylink"
                                  id="token-buy-button"
                                  onClick={copieBtn}
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(157 81 255)",
                                  }}
                                >
                                  <i class="fa fa-copy "></i>
                                </sapn>
                              </CopyToClipboard>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2">Date of Birth:</th>
                            <td colspan="2">
                              {" "}
                              {getuserdetaillist.DOB}
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2">Gender:</th>
                            <td colspan="2">
                              {" "}
                              {getuserdetaillist.gender}
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">Status:</th>
                            <td
                              style={{
                                color: !getuserdetaillist.is_active
                                  ? "red"
                                  : "green",
                              }}
                            >
                              {!getuserdetaillist.is_active
                                ? "Blocked"
                                : "Active"}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">Registration Date:</th>
                            <td
                              style={{
                                color: !getuserdetaillist.is_active
                                  ? "red"
                                  : "green",
                              }}
                            >
                              {moment(getuserdetaillist.created_at).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          </tr>
                          {getuserdetaillist?.bankDetails == null ? (
                            " "
                          ) : (
                            <>
                              <tr>
                                <th colspan="2">Bank Name:</th>
                                <td colspan="2">
                                  {" "}
                                  {getuserdetaillist?.bankDetails?.bankName}
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2">Account Number:</th>
                                <td colspan="2">
                                  {" "}
                                  {
                                    getuserdetaillist?.bankDetails
                                      ?.accountNumber
                                  }{" "}
                                  <CopyToClipboard
                                    text={
                                      getuserdetaillist?.bankDetails
                                        ?.accountNumber
                                    }
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2">IFSC Code:</th>
                                <td colspan="2">
                                  {" "}
                                  {
                                    getuserdetaillist?.bankDetails?.branchCode
                                  }{" "}
                                  <CopyToClipboard
                                    text={
                                      getuserdetaillist?.bankDetails?.branchCode
                                    }
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                            </>
                          )}
                          {getuserdetaillist?.CreditCard == null ? (
                            " "
                          ) : (
                            <>
                              <tr>
                                <th colspan="2">Credit Card No:</th>
                                <td colspan="2">
                                  {" "}
                                  {
                                    getuserdetaillist?.CreditCard?.cardNumber
                                  }{" "}
                                  &nbsp;
                                  <CopyToClipboard
                                    text={
                                      getuserdetaillist?.CreditCard?.cardNumber
                                    }
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2">CVV:</th>
                                <td colspan="2">
                                  {" "}
                                  {getuserdetaillist?.CreditCard?.cvv}{" "}
                                  <CopyToClipboard
                                    text={getuserdetaillist?.CreditCard?.cvv}
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2">Expiry:</th>
                                <td colspan="2">
                                  {" "}
                                  {
                                    getuserdetaillist?.CreditCard
                                      ?.expirationDate
                                  }{" "}
                                  <CopyToClipboard
                                    text={
                                      getuserdetaillist?.CreditCard
                                        ?.expirationDate
                                    }
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>{" "}
                            </>
                          )}
                          {getuserdetaillist?.PaypalInfo == null ? (
                            " "
                          ) : (
                            <>
                              <tr>
                                <th colspan="2">Paypal Id:</th>
                                <td colspan="2">
                                  {" "}
                                  {getuserdetaillist?.PaypalInfo.email}&nbsp;
                                  <CopyToClipboard
                                    text={getuserdetaillist?.PaypalInfo?.email}
                                  >
                                    <sapn
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i class="fa fa-copy "></i>
                                    </sapn>
                                  </CopyToClipboard>
                                </td>
                              </tr>{" "}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Userdetails;
