const config = {
  baseUrl: "/",
  // baseUrl: "/admin/",
  // baseUrl: "/admin/",

 "homepage": "https://admin.pokerfreebie.com",
  imageUrl: "http://18.135.33.83/poker/backend/uploads/",

  // apiUrl: "http://localhost:9001/api",
  apiUrl: "https://api.pokerfreebie.com/api",

  /************************************Live************************************* */

  /************************************************************************************** */

  adminAddress: "0x2E45F7Aeb070585d25678baa58e789B7cFb2836f", // For Referral
  // chainId : '0x61',  // Testnet
  chainId: "0x38", // Mainnet
};
export default config;
