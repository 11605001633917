import {
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper";

export const LoginAction = (data) => {
  return postRequest("admin-login", data).then((res) => {
    return res.data;
  });
};

// export const getDashboardStatisticsAction = (data) => {
//   return postRequest("users/all?count=true", data).then((res) => {
//     return res.data;
//   });
// };

export const deleteTournamentLevelAction = (data) => {
  return deleteRequest(`tournaments/delete/durationlevel/${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deletevedioAction = (data) => {
  return deleteRequest(`s3/${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteTournamentAction = (data) => {
  return deleteRequest(`tournaments/${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteuserAction = (data) => {
  return deleteRequest("admin/delete/user", data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const removeUserFromTournamentAction = (data) => {
  return postRequest("tournaments/leave", data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersListAction = (data) => {
  return getRequest(
    `users/all?take=${data.take}&skip=${data.skip}&type=${data.type}`
  ).then((res) => {
    return res.data;
  });
};

export const getSingleUserAction = (data) => {
  return getRequest(`get-user/${data}`).then((res) => {
    return res.data;
  });
};

export const getUsersCountAction = (data) => {
  return getRequest(`users/all?take=1000&type=${data.type}`).then((res) => {
    return res.data;
  });
};

export const getTournamentListAction = (data) => {
  return getRequest(`tournaments/all?skip=${data.skip}&take=${data.take}`).then(
    (res) => {
      return res.data;
    }
  );
};
export const getCsvFileUrl = () => {
  return getRequest("users/all/email").then((res) => {
    return res.data;
  });
};
export const getSingleTournamentListAction = (data) => {
  return getRequest(`tournaments/get/${data}`).then((res) => {
    return res.data;
  });
};
export const getSingleTournamentAnalyticsAction = (data) => {
  return getRequest(`tournaments/analytics/${data}`).then((res) => {
    return res.data;
  });
};
export const getVideosListAction = (data) => {
  return getRequest(
    `s3/cloudfront/download/all?skip=${data.skip}&take=${data.take}`
  ).then((res) => {
    return res.data;
  });
};

export const getWinnerListAction = (data) => {
  return getRequest(`tournments/get/${data}`).then((res) => {
    return res.data;
  });
};

// export const getPrizepoolListAction = (data) => {
//   return getRequest("tournaments/prizepool/all", data).then((res) => {
//     return res.data;
//   });
// };
export const getTournamentLevelListAction = (data) => {
  return getRequest(
    `tournaments/durationlevel/all?skip=${data.skip}&take=${data.take}`
  ).then((res) => {
    return res.data;
  });
};

export const getDefaultConfigListAction = (data) => {
  return getRequest(`tournaments/durationlevel/config`).then((res) => {
    return res.data;
  });
};

// export const loginAsUserAction = (data) => {
//   return postRequest("loginAsUser", data).then((res) => {
//     return res.data;
//   });
// };

// export const getUsersReferralsAction = (data) => {
//   return postRequest("getUsersReferrals", data).then((res) => {
//     return res.data;
//   });
// };

export const getallticketAction = (data) => {
  return getRequest("support", data).then((res) => {
    return res.data;
  });
};

// export const gettournamentdetailsAction = (data) => {
//   return postRequest("gettournamentdetails", data).then((res) => {
//     return res.data;
//   });
// };

// export const getTournamentUsersAction = (data) => {
//   return postRequest("getTournamentUsers", data).then((res) => {
//     return res.data;
//   });
// };

// export const gettournamentdetailsbyidAction = (data) => {
//   return postRequest("gettournamentdetailsbyid", data).then((res) => {
//     return res.data;
//   });
// };

// export const updatewinneramountAction = (data) => {
//   return postRequest("updatewinneramount", data).then((res) => {
//     return res.data;
//   });
// };

export const grantExtraChipsAction = (data, id) => {
  return postRequest(`admin/grant-chips/${id}`, data).then((res) => {
    return res.data;
  });
};
export const addtournamentAction = (data) => {
  return postRequest(
    `tournaments/create/${data.type == "MTT" ? "mtt" : "sng"}`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const updatetournamentAction = (data, id) => {
  return patchRequest(`tournaments/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const addticketAnswerAction = (data) => {
  const dataobj = {
    answer: data.answer,
  };
  return patchRequest(`support/answer/${data.id}`, dataobj)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addvideoAction = (data) => {
  return postRequestFormData(`s3/upload`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const addQuizAction = (data) => {
  return postRequest(`advertisement/quiz`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addRedirectUrlAction = (data, id) => {
  return patchRequest(`advertisement/url/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

// export const addprizepoolAction = (data) => {
//   return postRequest("tournaments/create/prizepool", data).then((res) => {
//     return res.data;
//   });
// };

export const addtournamentLevelAction = (data) => {
  return postRequest("tournaments/durationlevels/config", data).then((res) => {
    return res.data;
  });
};

export const changePasswordAction = (data) => {
  return postRequest("users/me/change-password", data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const UserBlockAction = (data) => {
  return patchRequest(`users/me/${data.id}`, data).then((res) => {
    return res.data;
  });
};
export const UserUpdateAction = (data) => {
  return patchRequest(`admin/users/me`, data).then((res) => {
    return res.data;
  });
};

// export const UserUnBlockAction = (data) => {
//   return postRequest("userUnblock", data).then((res) => {
//     return res.data;
//   });
// };

export const getuserDetailsAction = (data) => {
  return getRequest(`get-user/${data.id}`).then((res) => {
    return res.data;
  });
};

export const adduserbyadminAction = (data) => {
  return postRequest("create-user", data).then((res) => {
    return res.data;
  });
};

// export const addcoinbyadminAction = (data) => {
//   return postRequest("addcoinbyadmin", data).then((res) => {
//     return res.data;
//   });
// };

// export const updatewithdrawamountAction = (data) => {
//   return postRequest("updatewithdrawamount", data).then((res) => {
//     return res.data;
//   });
// };
