/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast, Toaster } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {
  getTournamentListAction,
  deleteTournamentAction,
  // getCsvFileUrl,
} from "../Action/action";
import moment from "moment";

const Tournament = () => {
  const [gettournamentlist, settournamentList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(10);
  const [pageCount, setpageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [tournament, settournament] = useState({});
  // const [csvUrl, setCsvUrl] = useState({});

  useEffect(() => {
    gettournament();
    // getCsvFile();
  }, [skip, count]);

  // const getCsvFile = async () => {
  //   try {
  //     const res = await getCsvFileUrl();
  //     console.log(res.url, "file url");
  //     if (res) {
  //       setCsvUrl(res.url);
  //     }
  //   } catch (error) {}
  // };

  const gettournament = async () => {
    let res = await getTournamentListAction({
      skip: skip,
      take: count,
    });
    setpageCount(Math.ceil(res.pagination.count / count));
    if (res) {
      setpageCount(Math.ceil(res.pagination.count / count));
      settournamentList(res.tournaments);
    }
  };

  const originalTime = "2024-01-12T12:21:00.000Z";
  const date = new Date(originalTime);
  const formattedTime = date.toLocaleString();
  console.log(formattedTime, "formattedTime");

  const handlePageClick = async (data) => {
    setSkip(data.selected * count);
    setDataCount(data.selected);
    const commentsFormServer = await gettournament(skip);
    settournamentList(commentsFormServer.tournaments);
    window.scrollTo(0, 0);
  };

  function editPartner(item) {
    settournament(item);
  }

  const tournamentDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Tournament!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#790bd9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteTournamentAction(e.id);
        if (!res.response) {
          Swal.fire(res, "", "success");
          gettournament();
        } else {
          toast.error(res.response.data);
        }
      }
    });
  };

  const columns = [
    {
      id: "Sno.",
      name: "S no.",
      selector: (row, index) => dataCount * count + index + 1,
    },
    {
      id: "title",
      name: (
        <>
          <div className="text-center">Name</div>
        </>
      ),
      selector: (item) => {
        return `${item.name}`;
      },
      minWidth: "180px",
    },

    {
      id: "start_time",
      name: (
        <>
          <div className="text-center">Start Time</div>
        </>
      ),
      selector: (item) => {
        return `${moment(item.start_time).format('YYYY-MM-DD hh:mm a')}`;
      },
      minWidth: "170px",
    },
    {
      id: "end_time",
      name: (
        <>
          <div className="text-center">End Time</div>
        </>
      ),
      selector: (item) => {
        return `${moment(item.end_time).format('YYYY-MM-DD hh:mm a')}`;
      },
      minWidth: "170px",
    },
    {
      id: "starting_chips",
      name: "Starting Chips",
      selector: (item) => {
        return `${item.starting_chips}`;
      },
      minWidth: "120px",
    },

    // {
    //   id: "late_registeration_time",
    //   name: (
    //     <>
    //       <div className="text-center">Late Registeration</div>
    //       <div className="text-center">Time</div>
    //     </>
    //   ),
    //   selector: (item) => {
    //     return `${moment(item.late_registeration_time).format('YYYY-MM-DD hh:mm a')}`;
    //   },
    //   minWidth: "170px",
    // },
    {
      id: "tournament_type",
      name: "Tournament Type",
      selector: (item) => {
        return `${
          item.game_type === "MTT" ? "MTT Tournament" : "SNG Tournament"
        }`;
      },
      minWidth: "150px",
    },
    // {
    //   id: "distribution",
    //   name: "Distribution",
    //   selector: (item) => {
    //     return `${item.distribution}`;
    //   },
    //   minWidth: "100px",
    // },
    // {
    //   id: "blinds",
    //   name: "Blinds",
    //   selector: (item) => {
    //     return `${item.blinds}`;
    //   },
    //   minWidth: "90px",
    // },
    {
      id: "tournament_type",
      name: "Joining Code",
      selector: (item) => {
        return `${item.joining_code == null ? "NA" : item.joining_code}`;
      },
      minWidth: "120px",
    },
    {
      id: "is_completed",
      name: "Status",
      selector: (item) => {
        return (
          <>
            {item.status}
          </>
        //    <>
        //    {item.is_completed ? (
        //      <>
        //        <div style={{ color: "#008000" }}>
        //          <span>Completed</span>
        //        </div>
        //      </>
        //    ) : (
        //      <>
        //        <div style={{ color: "red" }}>
        //          <span>Upcoming</span>
        //        </div>
        //      </>
        //    )}
        //  </>
        );
      },
      minWidth: "110px",
    },
    {
      id: "levels",
      name: (
        <>
          <div className="text-center">Level</div>
          <div className="text-center"> Configuration</div>
        </>
      ),
      selector: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => editPartner(item)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              View
            </button>
          </>
        );
      },
      minWidth: "140px",
    },
    {
      id: "prizepool",
      name: (
        <>
          <div className="text-center"> Prize Pool</div>
        </>
      ),
      selector: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => editPartner(item)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              View
            </button>
          </>
        );
      },
    },
    {
      id: "winnerlist",
      name: "Winner List",
      selector: (item) => {
        return (
          <a
            href={
              item.is_completed ? `${config.baseUrl}winnerList/${item.id}` : "#"
            }
          >
            {" "}
            <button
              disabled={!item.is_completed}
              className="btn btn-primary btn-sm"
            >
              View
            </button>
          </a>
        );
      },
    },
    {
      id: "players",
      name: "Players",
      selector: (item) => {
        return (
          <>
            <a
              href={
                item.status === 'Ended'
                  ? "#"
                  : `${config.baseUrl}editplayers/${item.id}`
              }
            >
              <button
                disabled={item.status === 'Ended'}
                type="button"
                className="btn btn-primary btn-sm"
              >
                View
              </button>
            </a>
         
          </>
        );
      },
      minWidth: "130px",
    },
    {
      id: "action",
      name: "Action",
      selector: (item) => {
        return (
          <>
            <a
              href={
                !item.is_completed
                  ? `${config.baseUrl}editTournament/${item.id}`
                  : "#"
              }
            >
              <button
                disabled={item.is_completed}
                type="button"
                className="btn btn-primary btn-sm"
              >
                Edit
              </button>
            </a>
            <button
              onClick={() => tournamentDelete(item)}
              className="btn btn-danger mx-1 btn-sm"
            >
              Delete
            </button>
          </>
        );
      },
      minWidth: "130px",
    },
  ];

  return (
    <>
      <div class="wrapper">
        <Header />
        <Toaster />
        <Sidebar />

        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Tournament List</h4>
                      <div>
                        <label>Count Per Page : </label>
                        <select
                          onChange={(e) => setCount(e.target.value)}
                          className="px-3 py-1 mx-2 "
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                        <a href={`${config.baseUrl}addTournament`}>
                          {" "}
                          <button type="button" className="btn btn-primary">
                            Add
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="box-body">
                      {/* <div className=" d-flex my-2 justify-content-end">
                        <a
                          className="my-anchor-element btn btn-primary  pull-right"
                          href={csvUrl}
                        >
                          {" "}
                          <MdDownload size={18} /> CSV
                        </a>
                        <Tooltip anchorSelect=".my-anchor-element" place="top">
                          Download CSV
                        </Tooltip>
                      </div> */}
                      <DataTable
                        striped
                        responsive
                        columns={columns}
                        data={gettournamentlist}
                      />
                    </div>
                    <div className="mx-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel1"
                      >
                        All Prize Pools
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        {tournament &&
                          tournament.prize_pool &&
                          tournament.prize_pool.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="mb-1 d-flex  mt-2"
                                style={{ width: "100%" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label className="text-white">
                                    {" "}
                                    {index +
                                      1 +
                                      ") " +
                                      " Rank- " +
                                      item.rank +
                                      " " +
                                      ", " +
                                      "Amount- " +
                                      item.amount}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        <div className="modal-footer mt-20">
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-light"
                        id="exampleModalLabel1"
                      >
                        Selected level Configurations
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        {tournament &&
                          tournament.levels &&
                          tournament.levels.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="mb-1 d-flex  mt-2"
                                style={{ width: "100%" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label className="text-white">
                                    {" "}
                                    {index +
                                      1 +
                                      ") " +
                                      "Blinds- " +
                                      item.blinds +
                                      " " +
                                      ", " +
                                      "Duration- " +
                                      item.duration +
                                      " " +
                                      ", " +
                                      "Break Duration- " +
                                      item.break_duration +
                                      " "}
                                  </label>
                                </div>
                              </div>
                            );
                          })}

                        <div className="modal-footer mt-20">
                          <button
                            type="button"
                            className="btn btn-secondary closeModal1"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Tournament;
